<template>
  <div class="hero-section-container d-flex justify-content-center align-items-center flex-column">
    <h1 class="fs-48 text-center" v-html="$t('CONTACT_US.CONTACT_US_HERO_SECTION_TITLE')"></h1>
    <p class="fs-20 text-center">{{ $t("CONTACT_US.CONTACT_US_HERO_SECTION_SUBTITLE") }}</p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
